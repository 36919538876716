import { graphql, navigate } from "gatsby"
import React from "react"
import styled from "styled-components"
import { SecondaryHeading } from "../components/common/heading.component"
import Section from "../components/common/sections/section.component"
import BlogChosenStories from "../components/community/blog/blog-chosen-stories"
import BlogInfo from "../components/community/blog/blog-info.component"
import BlogResults from "../components/community/blog/blog-results"
import { BlogIndex } from "../components/community/blog/blog.interface"
import Layout from "../components/layout"
import SEO from "../components/seo.component"
import InputSearch from "../components/common/input-search/input-search"
import { Path } from "../navigation/constants/routes"
import { DetectedBreadcrumbs, TopNavigationWrapper } from "../navigation"
import BlogFilters from "../components/community/blog/blog-filters"
import { ContentType } from "../constants/Query"
import { useFilteredContent } from "../helpers/get-filtered-content"
import Pagination from "../components/common/pagination.component"
import { AppFunctionComponent } from "../types"

const StoriesWrapper = styled(Section)`
  grid-row-gap: 30px;
  grid-auto-rows: auto;
  grid-template-columns: 100%;
`
const SearchInput = styled(InputSearch)`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    width: calc(50% - 10px);
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    width: calc(50% - 15px);
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.tabletHorizontal}px) {
    width: calc(33.33% - 20px);
    max-width: 360px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}px) {
    max-width: calc(33.33% - 25px);
  }
`

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
`

const ScrollAnchor = styled.span`
  display: block;
  margin-top: -130px;
  margin-bottom: 130px;
`
const redirectToPost = (id: string) => navigate(`${Path.Blog}${id}`)

const BlogIndexPage: AppFunctionComponent<BlogIndex> = ({
  data: {
    posts,
    featuredPosts,
    searchablePosts,
    meta,
    topNavigationContent: { navigationItems: topNavigationItems },
    footerNavigationContent: { navigationItems: footerNavigationItems },
    promobarContent,
  },
  pageContext: { pagination, pathPrefix },
}) => {
  const normalizedPosts = posts.nodes
  const normalizedFeaturedPosts = featuredPosts.nodes
  const normalizedSearchablePosts = searchablePosts.nodes.map(
    ({ postTitle, postSlug, postExcerpt: { postExcerpt }, postTags }) => ({
      id: postSlug,
      title: postTitle,
      context:
        postTitle +
        " " +
        postExcerpt +
        " " +
        (postTags && postTags.map((tag) => tag.text + " ")),
    })
  )

  const {
    pending,
    items,
    pagination: filteredPagination,
    queryParams,
  } = useFilteredContent({
    contentType: ContentType.Blog,
    allItems: normalizedPosts,
    totalPages: pagination.total!,
    basePath: Path.Blog.toString(),
    slug: "",
    page: pagination.currentPage!,
    limit: 9,
  })

  const normalizedPagination = {
    currentPage: pagination.currentPage!,
    pagePrefix: Path.Blog.toString(),
    nextPage:
      pagination.currentPage! < pagination.total!
        ? pagination.currentPage! + 1
        : null,
    prevPage:
      pagination.currentPage! === 1 ? null : pagination.currentPage! - 1,
  }

  const paginationComponent = (
    <Pagination
      current={filteredPagination.page}
      rootUrl={filteredPagination.basePath}
      total={filteredPagination.totalPages}
      query={queryParams}
      scrollTo="latest-stories"
    />
  )
  return (
    <Layout
      topNavigationItems={topNavigationItems}
      footerNavigationItems={footerNavigationItems}
      promobarContent={promobarContent}
      masterPage
    >
      <SEO title="Community" {...meta} pagination={normalizedPagination} />
      <TopNavigationWrapper breadcrumbsMenu={<DetectedBreadcrumbs />} />
      <BlogInfo />
      <BlogChosenStories featuredPosts={normalizedFeaturedPosts} />
      <StoriesWrapper>
        <SearchWrapper>
          <SecondaryHeading renderAs={"h2"}>
            <ScrollAnchor id={"latest-stories"} />
            Latest stories
          </SecondaryHeading>
          <SearchInput
            placeholder={"Search on blog"}
            label={"Search on blog"}
            items={normalizedSearchablePosts}
            onItemMouseDown={redirectToPost}
          />
        </SearchWrapper>
        <BlogFilters
          basePath={pathPrefix + Path.Blog.toString()}
          oneFilterAtTime
        />
        <BlogResults
          pending={pending}
          pagination={paginationComponent}
          posts={items}
        />
      </StoriesWrapper>
    </Layout>
  )
}

export default BlogIndexPage

export const query = graphql`
  query BlogPostsPageQuery(
    $postsIds: [String!]!
    $featuredPostsIds: [String!]!
    $locale: String! = "en-US"
    $language: String!
  ) {
    meta: contentfulPageMeta(page: { eq: "community/blog" }) {
      ...PageMeta
    }
    searchablePosts: allContentfulBlogPost(
      limit: 512
      sort: { fields: postOriginalPublishDate, order: DESC }
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        postSlug
        postTitle
        postTags {
          text
        }
        postExcerpt {
          postExcerpt
        }
      }
    }
    posts: allContentfulBlogPost(
      filter: { id: { in: $postsIds }, node_locale: { eq: $locale } }
      sort: { fields: postOriginalPublishDate, order: DESC }
    ) {
      nodes {
        postSlug
        postTitle
        postOriginalPublishDate
        postOriginalPublishDateFormatted: postOriginalPublishDate(
          formatString: "MMM D, YYYY"
        )
        postTags {
          text
        }
        postExcerpt {
          postExcerpt
        }
        postFeaturedImage {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            quality: 100
            width: 400
          )
          title
          description
        }
        postDiscourseId
      }
    }
    featuredPosts: allContentfulBlogPost(
      filter: { id: { in: $featuredPostsIds }, node_locale: { eq: $locale } }
      sort: { fields: postOriginalPublishDate, order: DESC }
    ) {
      nodes {
        postSlug
        postTitle
        postOriginalPublishDate
        postOriginalPublishDateFormatted: postOriginalPublishDate(
          formatString: "MMM D, YYYY"
        )
        postTags {
          text
        }
        postExcerpt {
          postExcerpt
        }
        postFeaturedImage {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            quality: 100
            width: 600
          )
          title
          description
        }
      }
    }
    topNavigationContent: contentfulNavigation(
      contentfulid: { eq: "top-navigation" }
      node_locale: { eq: $locale }
    ) {
      ...Navigation
      node_locale
    }
    footerNavigationContent: contentfulNavigation(
      contentfulid: { eq: "footer-navigation" }
      node_locale: { eq: $locale }
    ) {
      ...Navigation
      node_locale
    }
    promobarContent: contentfulText(
      contentful_id: { eq: "63Ao7XUYTc7UtDipcRpwoc" }
      node_locale: { eq: $locale }
    ) {
      text {
        raw
      }
      node_locale
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
